import React from 'react'
import styled from 'styled-components'

interface SubTitleProps {
    index: number
    title: string
}

export const SubTitle = ({ title, index }: SubTitleProps) => (
    <Title>
        <Index>{index}</Index>
        {title}
    </Title>
)

const Title = styled.h3`
    margin: 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    font-family: ${({ theme }) => theme.typography.bolderFontFamily};
    font-weight: 400;
`

const Index = styled.span`
    width: 60px;
    height: 60px;
    font-size: 26px;
    margin-right: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 10px solid ${({ theme }) => theme.colors.accent};
    color: #555;
    flex-shrink: 0;
`
