import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, ContentSpacer, LocaleConfig, TextBlock } from '../core'
import { SubTitle } from './SubTitle'
import { CourseCard } from './CourseCard'

interface CourseProps {
    locale: LocaleConfig
    title: string
    content: string
}

type CourseId =
    | 'LIC'
    | '470'
    | '650'
    | '800'
    | 'GE_LIC'
    | 'GE_BEGINNER'
    | 'GE_INTERMEDIATE'
    | 'GE_ADVANCED'

const courseColors: Record<CourseId, string> = {
    LIC: '#e1bd5f',
    '470': '#459eb0',
    '650': '#f08522',
    '800': '#01877c',
    GE_LIC: '#ffc107',
    GE_BEGINNER: '#3775d9',
    GE_INTERMEDIATE: '#353a6d',
    GE_ADVANCED: '#966d36',
}

export interface CoursesData {
    allCoursesYaml: {
        edges: {
            node: {
                id: CourseId
                name: { ja: string; en: string }
                description: { ja: string; en: string }
            }
        }[]
    }
}

export const Course = ({ locale, title, content }: CourseProps) => {
    const data: CoursesData = useStaticQuery(graphql`
        query CoursesQuery {
            allCoursesYaml {
                edges {
                    node {
                        id: yamlId
                        name {
                            ja
                            en
                        }
                        description {
                            ja
                            en
                        }
                    }
                }
            }
        }
    `)

    const courses = data.allCoursesYaml.edges.map((edge) => ({
        id: edge.node.id,
        name: edge.node.name[locale.locale],
        description: edge.node.description[locale.locale],
        is_toeic: edge.node.id.indexOf('GE_') === -1,
    }))

    const toeicCourses = courses.filter((course) => course.is_toeic)
    const generalCourses = courses.filter((course) => !course.is_toeic)

    return (
        <Container>
            <InnerContainer>
                <Info>
                    <SubTitle index={1} title={title} />
                    <ContentSpacer />
                    <TextBlock dangerouslySetInnerHTML={{ __html: content }} />
                </Info>
                <Courses>
                    <CourseColumn>
                        {toeicCourses.map(({ id, name, description }) => (
                            <CourseCard
                                key={id}
                                name={name}
                                description={description}
                                backgroundColor={courseColors[id]}
                            />
                        ))}
                    </CourseColumn>
                    <CourseColumn>
                        {generalCourses.map(({ id, name, description }) => (
                            <CourseCard
                                key={id}
                                name={name}
                                description={description}
                                backgroundColor={courseColors[id]}
                            />
                        ))}
                    </CourseColumn>
                </Courses>
            </InnerContainer>
        </Container>
    )
}

const InnerContainer = styled.div`
    display: flex;

    @media (max-width: 500px) {
        flex-direction: column;
    }
`

const Info = styled.div`
    width: 48%;

    @media (max-width: 500px) {
        width: 100%;
    }
`

const Courses = styled.div`
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 500px) {
        width: 100%;
        margin-top: 24px;
    }
`
const CourseColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    padding: 0 20px;
`
