import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { SubTitle } from './SubTitle'

interface StudyFlowSectionProps {
    index: number
    title: string
}

export const StudyFlowSection = ({
    index,
    title,
    children,
}: PropsWithChildren<StudyFlowSectionProps>) => {
    return (
        <Container>
            <SubTitle index={index} title={title} />
            {children}
        </Container>
    )
}

const Container = styled.div``
