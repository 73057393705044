import React from 'react'
import { withPrefix } from 'gatsby'
import styled from 'styled-components'
import { Container, ContentSpacer, TextBlock } from '../core'
import { SubTitle } from './SubTitle'

interface CertificateProps {
    title: string
    content: string
}

export const Certificate = ({ title, content }: CertificateProps) => {
    return (
        <Container>
            <InnerContainer>
                <Info>
                    <SubTitle index={4} title={title} />
                    <ContentSpacer />
                    <TextBlock dangerouslySetInnerHTML={{ __html: content }} />
                </Info>
                <CertificateIllustration data-testid="study-certificate-illustration" />
            </InnerContainer>
        </Container>
    )
}

const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        margin-bottom: 0;
    }
`

const Info = styled.div`
    width: 48%;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
        margin-bottom: 16px;
    }
`

const CertificateIllustration = styled.div`
    width: 48%;
    height: 380px;
    background-image: url('${withPrefix('/images/certificate.png')}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
    }
`
