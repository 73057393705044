import React from 'react'
import { withPrefix, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, ContentSpacer, LocaleConfig, TextBlock } from 'modules/core'
import {
    flattenEdgesNodes,
    getMatchingImageSrc,
    ImageFilesData,
} from 'modules/core/graphql'
import { SubTitle } from './SubTitle'

interface OnboardingProps {
    locale: LocaleConfig
    title: string
    content: string
}

export interface OnboardingData {
    allOnboardingYaml: {
        edges: Array<{
            node: {
                id: string
                title: { ja: string; en: string }
                content: { ja: string; en: string }
                illustration: string
            }
        }>
    }
    allFile: ImageFilesData
}

export const Onboarding = ({ locale, title, content }: OnboardingProps) => {
    const data = useStaticQuery<OnboardingData>(graphql`
        query OnboardingQuery {
            allOnboardingYaml {
                edges {
                    node {
                        id: yamlId
                        title {
                            ja
                            en
                        }
                        content {
                            ja
                            en
                        }
                        illustration
                    }
                }
            }
            allFile(filter: { relativePath: { glob: "study/*.png" } }) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                width: 600
                                quality: 100
                            )
                        }
                    }
                }
            }
        }
    `)

    const illustrations = flattenEdgesNodes(data.allFile)
    const onboardingSteps = flattenEdgesNodes(data.allOnboardingYaml).map(
        (node) => ({
            id: node.id,
            title: node.title[locale.locale],
            content: node.content[locale.locale],
            illustration: getMatchingImageSrc(
                illustrations,
                node.illustration
            )!,
        })
    )

    return (
        <Container>
            <InnerContainer>
                <Info>
                    <SubTitle index={2} title={title} />
                    <ContentSpacer />
                    <TextBlock dangerouslySetInnerHTML={{ __html: content }} />
                </Info>
                <OnboardingIllustration data-testid="study-onboarding-illustration" />
            </InnerContainer>
            <OnboardingSteps>
                {onboardingSteps.map(({ id, title, content, illustration }) => (
                    <OnboardingStep key={id}>
                        <OnboardingStepIllustration
                            src={illustration}
                            data-testid="study-onboarding-onboardingstepillustration"
                        />
                        <OnboardingStepContent>
                            <OnboardingStepTitle>{title}</OnboardingStepTitle>
                            {content}
                        </OnboardingStepContent>
                    </OnboardingStep>
                ))}
            </OnboardingSteps>
        </Container>
    )
}

const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 80px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        margin-bottom: 0;
    }
`

const Info = styled.div`
    width: 48%;

    @media (max-width: 500px) {
        width: 100%;
    }
`

const OnboardingIllustration = styled.div`
    width: 48%;
    height: 240px;
    background-image: url('${withPrefix('/images/onboarding.png')}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
    }
`

const OnboardingSteps = styled.div`
    @media ${({ theme }) => theme.breakpoints.phone_only} {
        margin: 0 -12px;
    }
`

const OnboardingStep = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 90%;
    background-color: ${({ theme }) => theme.colors.lightBackground};
    padding: 32px 40px;
    margin: 0 auto;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        flex-direction: column-reverse;
        width: 100%;
        padding: 12px;
    }
`
const OnboardingStepIllustration = styled.img`
    width: 60%;
    height: 100%;
    margin-left: -100px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
        margin: 0;
    }
`

const OnboardingStepContent = styled.div`
    width: 44%;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 0 auto;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
        margin: 0 auto 12px auto;
    }
`

const OnboardingStepTitle = styled.div`
    font-size: 20px;
    font-family: ${({ theme }) => theme.typography.bolderFontFamily};
    font-weight: 400;
    margin-bottom: 6px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        margin: 32px 0 16px 0;
    }
`
