import React from 'react'
import styled from 'styled-components'

interface CourseCardProps {
    name: string
    description: string
    backgroundColor: string
}

export const CourseCard = ({
    name,
    description,
    backgroundColor,
}: CourseCardProps) => (
    <CourseCardContainer>
        <CourseCardHeader backgroundColor={backgroundColor}>
            <CourseCardHeaderInner
                dangerouslySetInnerHTML={{
                    __html: name,
                }}
                data-testid="study-course-coursename"
            />
        </CourseCardHeader>
        <CourseCardFooter>{description}</CourseCardFooter>
    </CourseCardContainer>
)

const CourseCardContainer = styled.div`
    border-radius: 6px;
    height: 155px;
    background-color: #f4f4f4;
    margin-bottom: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`

const CourseCardHeader = styled.div<{ backgroundColor: string }>`
    width: 100%;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${({ backgroundColor }) => backgroundColor};

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        height: 66px;
    }
`

const CourseCardHeaderInner = styled.div`
    width: 100%;
    color: white;
    white-space: pre-wrap;
    text-align: center;
    font-size: 14px;
    line-height: 21px;

    em {
        font-size: 22px;
        line-height: 26px;
        font-style: normal;
        font-weight: ${({ theme }) => theme.typography.weights.bold};
    }

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        font-size: 10px;
        line-height: 13px;

        em {
            font-size: 18px;
            line-height: 24px;
        }
    }
`

const CourseCardFooter = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    white-space: pre-wrap;
    text-align: center;
    color: ${({ theme }) => theme.colors.mutedText};
    padding: 0 2px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        height: 81px;
        padding: 4px;
        align-items: flex-start;
    }
`
