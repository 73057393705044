import React from 'react'
import styled from 'styled-components'
import { TextBlock } from '../core'

interface StepsItemProps {
    illustration: string
    title: string
    content: string
}

export const StepsItem = ({ illustration, title, content }: StepsItemProps) => (
    <Container>
        <Illustration
            image={illustration}
            data-testid="study-studysteps-illustration"
        />
        <Title>{title}</Title>
        <Content
            dangerouslySetInnerHTML={{ __html: content }}
            data-testid="study-studysteps-content"
        />
    </Container>
)

const Container = styled.div`
    width: 32%;
    margin-bottom: 32px;

    @media (max-width: 500px) {
        width: 100%;
    }
`

const Illustration = styled.div<{ image: string }>`
    height: 190px;
    background-color: #efd02b;
    background-image: url('${({ image }) => image}');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        height: 180px;
    }
`

const Title = styled.h3`
    font-size: 20px;
    margin: 8px 0;
`

const Content = styled(TextBlock)`
    font-size: 14px;
    line-height: 22px;

    small {
        display: inline-block;
        font-size: 10px;
        line-height: 13px;
    }
`
