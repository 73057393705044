import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, ContentSpacer, LocaleConfig, TextBlock } from 'modules/core'
import {
    flattenEdgesNodes,
    getMatchingImageSrc,
    ImageFilesData,
} from 'modules/core/graphql'
import { SubTitle } from './SubTitle'
import { StepsItem } from './StepsItem'

interface StepsProps {
    locale: LocaleConfig
    title: string
    content: string
}

export interface StepsData {
    allStudyStepsYaml: {
        edges: Array<{
            node: {
                id: string
                title: { ja: string; en: string }
                content: { ja: string; en: string }
                illustration: string
            }
        }>
    }
    allFile: ImageFilesData
}

export const Steps = ({ locale, title, content }: StepsProps) => {
    const data: StepsData = useStaticQuery(graphql`
        query StudyStepsQuery {
            allStudyStepsYaml {
                edges {
                    node {
                        id: yamlId
                        title {
                            ja
                            en
                        }
                        content {
                            ja
                            en
                        }
                        illustration
                    }
                }
            }
            allFile(
                filter: { relativePath: { glob: "study/studystep_*.png" } }
            ) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                width: 500
                                quality: 100
                            )
                        }
                    }
                }
            }
        }
    `)

    const illustrations = flattenEdgesNodes(data.allFile)
    const steps = flattenEdgesNodes(data.allStudyStepsYaml).map((node) => ({
        id: node.id,
        title: node.title[locale.locale],
        content: node.content[locale.locale],
        illustration: getMatchingImageSrc(illustrations, node.illustration)!,
    }))

    return (
        <Container>
            <SubTitle index={3} title={title} />
            <ContentSpacer />
            <TextBlock dangerouslySetInnerHTML={{ __html: content }} />
            <ContentSpacer />
            <StepsContainer>
                {steps.map((step) => (
                    <StepsItem
                        key={step.id}
                        illustration={step.illustration}
                        title={step.title}
                        content={step.content}
                    />
                ))}
            </StepsContainer>
        </Container>
    )
}

const StepsContainer = styled.div`
    width: 100%;
    max-width: 960px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`
