import React from 'react'
import { graphql } from 'gatsby'
import {
    Layout,
    SEO,
    PageTitle,
    SectionSpacer,
    usePageSections,
    useTranslations,
    LocaleConfig,
} from '../modules/core'
import { Course, Steps } from '../modules/study'
import { Onboarding } from '../modules/study/Onboarding'
import { Certificate } from '../modules/study/Certificate'

type StudySection = 'courseSelection' | 'onboarding' | 'steps' | 'certificate'

export const query = graphql`
    query StudyQuery($locale: String) {
        allMarkdownRemark(
            filter: {
                frontmatter: { page: { eq: "study" }, locale: { eq: $locale } }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        section
                        title
                    }
                    html
                }
            }
        }
    }
`

interface StudyPageProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
    }
    data: {
        allMarkdownRemark: {
            edges: Array<{
                node: {
                    frontmatter: {
                        section: StudySection
                        title: string
                    }
                    html: string
                }
            }>
        }
    }
}

const StudyPage = ({ path, pageContext, data }: StudyPageProps) => {
    const { translate } = useTranslations(pageContext.localeConfig.locale)

    const sections = usePageSections<StudySection>(data)

    return (
        <Layout locale={pageContext.localeConfig} path={path}>
            <SEO title={translate('page_study')} />
            <PageTitle>{translate('page_study')}</PageTitle>
            <Course
                locale={pageContext.localeConfig}
                title={sections.courseSelection.title}
                content={sections.courseSelection.content}
            />
            <SectionSpacer />
            <Onboarding
                locale={pageContext.localeConfig}
                title={sections.onboarding.title}
                content={sections.onboarding.content}
            />
            <SectionSpacer />
            <Steps
                locale={pageContext.localeConfig}
                title={sections.steps.title}
                content={sections.steps.content}
            />
            <SectionSpacer />
            <Certificate
                title={sections.certificate.title}
                content={sections.certificate.content}
            />
        </Layout>
    )
}

export default StudyPage
